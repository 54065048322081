'use client';

import Image, { StaticImageData } from 'next/image';
import React from 'react';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';

export interface CarouselItemImmage {
    type: 'image';
    src: string | StaticImageData;
}
export interface CarouselItemVideo {
    type: 'video';
    src: string;
}

export type CarouselItem = CarouselItemImmage | CarouselItemVideo;

interface Props {
    items: CarouselItem[];
}

const Index = (props: Props) => {
    const { items } = props;
    const [currentIndex, setCurrentIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [items.length]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
    };

    const handleResetAutoplay = () => {
        // Reset the autoplay timer here if needed
    };

    return (
        <div className="px-2 group h-full w-full">
            <div className="relative max-w-5xl h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] mx-auto md:shadow-md rounded-xl overflow-hidden">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`w-full h-full block ${
                            index === currentIndex ? 'opacity-100' : 'opacity-0'
                        } transition-opacity duration-500 ease-in-out rounded-xl overflow-hidden absolute`}
                    >
                        {item.type === 'image' ? (
                            <Image
                                key={index}
                                className="max-w-full h-full object-scale-down"
                                src={item.src}
                                alt={`Slide ${index}`}
                                width={1920}
                                height={1080}
                            />
                        ) : (
                            <video className="w-full h-full object-cover" controls>
                                <source src={item.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                ))}
                <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center h-8 bg-slate-900">
                    {items.map((_, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                handleDotClick(index);
                                handleResetAutoplay();
                            }}
                            className={`w-3 h-3 mx-2 cursor-pointer rounded-full ${
                                index === currentIndex ? 'bg-blue-500' : 'bg-gray-100'
                            }`}
                        ></div>
                    ))}
                </div>
                <button
                    className="absolute group-hover:opacity-100 opacity-10 transition-all top-1/2 left-4 transform -translate-y-1/2 cursor-pointer text-slate-500 hover:text-slate-900 bg-slate-900/10 hover:bg-slate-900/30 rounded-full p-1"
                    onClick={() => {
                        handlePrev();
                        handleResetAutoplay();
                    }}
                    aria-label="Previous slide"
                >
                    {/* Your custom previous arrow icon */}
                    <span className="text-xl">
                        <MdOutlineArrowBackIos />
                    </span>
                </button>
                <button
                    className="absolute group-hover:opacity-100 opacity-10 transition-all top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-slate-500 hover:text-slate-900 bg-slate-900/10 hover:bg-slate-900/30 rounded-full p-1"
                    onClick={() => {
                        handleNext();
                        handleResetAutoplay();
                    }}
                    aria-label="Next slide"
                >
                    {/* Your custom next arrow icon */}
                    <span className="text-2xl">
                        <MdOutlineArrowForwardIos />
                    </span>
                </button>
            </div>
        </div>
    );
};

export default Index;
