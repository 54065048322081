'use client';

import React from 'react';

const Index = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    React.useEffect(() => {
        const cookies = document.cookie.split(';');
        let role = '';
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].includes('role')) {
                role = cookies[i].split('=')[1];
            }
        }

        if (role === 'student' || role === 'teacher') {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    return (
        <div
            className={
                'text-5xl sm:text-6xl md:text-7xl font-semibold bg-gradient-to-tr  text-transparent bg-clip-text text-center ' +
                (!isLoggedIn
                    ? 'from-blue-600 via-blue-400 to-green-300'
                    : 'from-red-600 via-red-400 to-amber-300')
            }
        >
            Physical Education
        </div>
    );
};

export default Index;
