'use client';

import Link from 'next/link';
import React from 'react';

const Index = () => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [role, setRole] = React.useState('' as string);
    React.useEffect(() => {
        const cookies = document.cookie.split(';');
        let role = '';
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].includes('role')) {
                role = cookies[i].split('=')[1];
            }
        }
        if (role === 'student' || role === 'teacher') {
            setIsLoggedIn(true);
            setRole(role);
        } else {
            setIsLoggedIn(false);
            setRole('');
        }
    }, []);

    if (isLoggedIn) {
        return (
            <div className="flex flex-col items-center justify-center gap-4">
                <Link
                    href={process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/'}
                    referrerPolicy="no-referrer"
                    className="text-3xl bg-gradient-to-tr from-red-500 via-red-400 to-amber-300 text-white rounded-[5rem] font-semibold px-8 lg:px-12 py-5 gap-2 hover:scale-105 outline outline-offset-2 outline-4 focus:outline-orange-300 focus:scale-105 transition-all uppercase"
                >
                    Go to portal
                </Link>
                <p className="text-slate-600 text-center">
                    You are already logged in as a {role}. Click the button to go to the portal.
                </p>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center gap-4">
            <Link
                href={process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/signup'}
                referrerPolicy="no-referrer"
                className="text-3xl bg-gradient-to-tr from-blue-600 via-blue-400 to-green-300 text-white rounded-[5rem] font-semibold px-8 lg:px-12 py-5 gap-2 hover:scale-105 outline outline-offset-2 outline-4 focus:outline-blue-300 focus:scale-105 transition-all uppercase"
            >
                Try now
            </Link>
            <p className="text-slate-600 text-center">
                Signup Today and get free access to the portal.
            </p>
        </div>
    );
};

export default Index;
