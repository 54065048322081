'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import Cookie from '../../assets/images/cookie.svg';

let timeout: NodeJS.Timeout | undefined = undefined;

const Index = () => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (localStorage.getItem('cookie-consent')) {
            setShow(false);
        } else {
            timeout = setTimeout(() => {
                setShow(true);
            }, 5000);
        }
        return () => clearTimeout(timeout);
    }, []);

    React.useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [show]);

    const handleAccept = () => {
        localStorage.setItem('cookie-consent', 'true');
        setShow(false);
    };

    if (!show) {
        return null;
    }

    return (
        <div className="fixed z-[10000] bg-slate-500/50 top-0 left-0 w-screen h-screen flex items-center justify-center">
            <div className="p-4">
                <div className="bg-white max-w-[580px] w-full shadow-lg p-4 rounded-xl">
                    <div className="flex items-center justify-center mt-4">
                        <Image width={70} height={70} src={Cookie} alt="cookie" />
                    </div>
                    <h1>
                        <strong>Cookies Policy</strong>
                    </h1>
                    <p className="text-sm mt-4">
                        This website uses cookies to ensure you get the best experience on our
                        website. We use cookie only for login session management and analytics
                        purposes.
                    </p>
                    <p className="text-sm mt-4">
                        For more information, please read our{' '}
                        <Link href="/terms" className="text-blue-500">
                            Terms and Conditions
                        </Link>{' '}
                        and{' '}
                        <Link href="/data-protection" className="text-blue-500">
                            Privacy Policy
                        </Link>
                        .
                    </p>
                    <div
                        className="flex items-center justify-end gap-4 mt-4"
                        onClick={handleAccept}
                    >
                        <button className="bg-green-600 text-white px-4 py-2 rounded-md">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
